/* Cubic Bezier Transition */
/*********** 
    Page Header
    ***********/
/* Header search bar, toggler button & top menu */
.page-header.navbar {
  /* Top notification menu/bar */
  /* Header seaech box */ }
  .page-header.navbar .page-logo {
    background: #FB5557; }
  .page-header.navbar .page-top {
    box-shadow: 0px 1px 10px 0px rgba(50, 50, 50, 0.2);
    background: #ffffff; }
  .page-header.navbar .top-menu .navbar-nav {
    /* Extended Dropdowns */
    /* Notification */
    /* Inbox */
    /* Tasks */
    /* User */
    /* Language */
    /* Dark version */ }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle:hover {
      background-color: #f9fafc; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle > i {
      color: #C0CDDC; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle .badge.badge-default {
      background-color: #FB5557;
      color: #ffffff; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown.open .dropdown-toggle {
      background-color: #f9fafc; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu:before {
      border-bottom-color: #e4e8ee; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu {
      border: 1px solid #e4e8ee; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu:before {
        border-bottom-color: #d4dae4; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu:after {
        border-bottom-color: #eaedf2; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external {
        background: #eaedf2; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > h3 {
          color: #62878f; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a {
          color: #337ab7; }
          .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a:hover {
            color: #23527c;
            text-decoration: none; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a {
        border-bottom: 1px solid #EFF2F6 !important;
        color: #888888; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a:hover {
          background: #f8f9fa; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li > a .time {
      background: #f1f1f1; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li > a:hover .time {
      background: #e4e4e4; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-toggle > .circle {
      background-color: #FB5557;
      color: #ffffff; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-toggle > .corner {
      border-color: transparent transparent transparent #FB5557; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu .dropdown-menu-list .subject .from {
      color: #5b9bd1; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list .progress {
      background-color: #dfe2e9; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > .username {
      color: #7f96ac; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > i {
      color: #7f96ac; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-menu {
      width: 195px; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle > .langname {
      color: #7f96ac; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu {
      background: #536470;
      border: 0; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu:after {
        border-bottom-color: #536470; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external {
        background: #414f59; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external > h3 {
          color: #c5ced4; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external > a:hover {
          color: #5496cf; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a,
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a {
        color: #d4dadf;
        border-bottom: 1px solid #607482 !important; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a > i,
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a > i {
          color: #b6c2c9; }
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a:hover,
        .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a:hover {
          background: #5b6f7c; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a {
        border-bottom: 0 !important; }
      .page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li.divider {
        background: #607482; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list > li > a .time {
      background: #485762; }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list > li > a:hover .time {
      background: #3d4a53; }
  .page-header.navbar .search-form {
    background: #fff; }
    .page-header.navbar .search-form .input-group .form-control {
      color: #7f96ac; }
      .page-header.navbar .search-form .input-group .form-control::-moz-placeholder {
        color: #7c94aa;
        opacity: 1; }
      .page-header.navbar .search-form .input-group .form-control:-ms-input-placeholder {
        color: #7c94aa; }
      .page-header.navbar .search-form .input-group .form-control::-webkit-input-placeholder {
        color: #7c94aa; }
    .page-header.navbar .search-form .input-group .input-group-btn .btn.submit > i {
      color: #7f96ac; }

/* Page sidebar */
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover,
.page-sidebar {
  background-color: #3D4A53;
  /* Default sidebar */ }
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu,
  .page-sidebar .page-sidebar-menu {
    /* 1st level links */
    /* All links */ }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a,
    .page-sidebar .page-sidebar-menu > li > a {
      border-top: 1px solid #43525c;
      color: #dce2e6; }
      @media (max-width: 991px) {
        .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a,
        .page-sidebar .page-sidebar-menu > li > a {
          /* 991px */
          border-top: 1px solid #4e5f6b; } }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i,
      .page-sidebar .page-sidebar-menu > li > a > i {
        color: #019660; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class^="icon-"],
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class*="icon-"],
      .page-sidebar .page-sidebar-menu > li > a > i[class^="icon-"],
      .page-sidebar .page-sidebar-menu > li > a > i[class*="icon-"] {
        color: #01b070; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li > a > .arrow.open:before {
        color: #576976; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a,
    .page-sidebar .page-sidebar-menu > li:hover > a,
    .page-sidebar .page-sidebar-menu > li.open > a {
      background: #37424a;
      color: #fafbfb; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > i,
      .page-sidebar .page-sidebar-menu > li:hover > a > i,
      .page-sidebar .page-sidebar-menu > li.open > a > i {
        color: #01b070; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li:hover > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li:hover > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li.open > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li.open > a > .arrow.open:before {
        color: #01b070; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a,
    .page-sidebar .page-sidebar-menu > li.active > a,
    .page-sidebar .page-sidebar-menu > li.active.open > a {
      background: #344047;
      color: white; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a:hover, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a:hover,
      .page-sidebar .page-sidebar-menu > li.active > a:hover,
      .page-sidebar .page-sidebar-menu > li.active.open > a:hover {
        background: #39454d; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > i,
      .page-sidebar .page-sidebar-menu > li.active > a > i,
      .page-sidebar .page-sidebar-menu > li.active.open > a > i {
        color: #fb5f61; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li.active > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li.active > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu > li.active.open > a > .arrow:before,
      .page-sidebar .page-sidebar-menu > li.active.open > a > .arrow.open:before {
        color: #ffebeb; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:last-child > a,
    .page-sidebar .page-sidebar-menu > li:last-child > a {
      border-bottom: 1px solid transparent !important; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow.open:before,
    .page-sidebar .page-sidebar-menu li > a > .arrow:before,
    .page-sidebar .page-sidebar-menu li > a > .arrow.open:before {
      color: #576976; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li:hover > a > .arrow.open:before,
    .page-sidebar .page-sidebar-menu li:hover > a > .arrow:before,
    .page-sidebar .page-sidebar-menu li:hover > a > .arrow.open:before {
      color: #01b070; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a > .arrow.open:before,
    .page-sidebar .page-sidebar-menu li.active > a > .arrow:before,
    .page-sidebar .page-sidebar-menu li.active > a > .arrow.open:before {
      color: #ffebeb; }
    .page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu:hover .sub-menu, .page-sidebar-closed
    .page-sidebar .page-sidebar-menu:hover .sub-menu {
      background: #303a41; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a,
    .page-sidebar .page-sidebar-menu .sub-menu > li > a {
      color: #ced5db; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i,
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > i {
        color: #768b9a; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i[class^="icon-"],
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i[class*="icon-"],
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class^="icon-"],
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class*="icon-"] {
        color: #8497a5; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow.open:before {
        color: #576976; }
    .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a,
    .page-sidebar .page-sidebar-menu .sub-menu > li:hover > a,
    .page-sidebar .page-sidebar-menu .sub-menu > li.open > a,
    .page-sidebar .page-sidebar-menu .sub-menu > li.active > a {
      background: #37424a !important; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > i,
      .page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > i,
      .page-sidebar .page-sidebar-menu .sub-menu > li.open > a > i,
      .page-sidebar .page-sidebar-menu .sub-menu > li.active > a > i {
        color: #01b070;
        color: #b0bdc5; }
      .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow.open:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li.active > a > .arrow:before,
      .page-sidebar .page-sidebar-menu .sub-menu > li.active > a > .arrow.open:before {
        color: #01b070; }

/******
    Page Footer 
    ******/
.page-footer .page-footer-inner {
  color: #bfc9d0; }

.page-footer-fixed .page-footer {
  background-color: #cdd2cf; }
  .page-footer-fixed .page-footer .page-footer-inner {
    color: #121618; }

.page-boxed .page-footer .page-footer-inner {
  color: #121618; }

.page-footer-fixed .page-boxed .page-footer {
  background-color: #cdd2cf; }

@media (min-width: 992px) {
  /* 992px */
  /* Sidebar menu closed */
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    box-shadow: 5px 5px rgba(48, 48, 48, 0.2); }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
    box-shadow: 5px 5px rgba(48, 48, 48, 0.2); }
    .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu {
      box-shadow: 5px 5px rgba(48, 48, 48, 0.2); }
  /* Fixed Sidebar */
  .page-sidebar-fixed:not(.page-footer-fixed) .page-content {
    border-bottom: 0; }
  .page-sidebar-fixed:not(.page-footer-fixed) .page-footer {
    background-color: #eef1f5; }
    .page-sidebar-fixed:not(.page-footer-fixed) .page-footer .page-footer-inner {
      color: #333; }
  /* Boxed Layout */
  .page-boxed {
    background-color: #c5cbc7 !important;
    /* Page container */ }
    .page-boxed .page-container {
      background-color: #3D4A53; }
    .page-boxed.page-sidebar-reversed .page-container {
      border-left: 0; }
    .page-boxed.page-sidebar-fixed .page-container {
      border-left: 0;
      border-bottom: 0; }
    .page-boxed.page-sidebar-reversed.page-sidebar-fixed .page-container {
      border-left: 0;
      border-right: 0;
      border-bottom: 0; }
  /* Sidebar Menu Wirh Hoverable Submenu */
  .page-sidebar-menu-hover-submenu li:hover a > .arrow {
    border-right: 8px solid #303a41; }
    .page-sidebar-reversed .page-sidebar-menu-hover-submenu li:hover a > .arrow {
      border-left: 8px solid #303a41; }
  .page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    background: #303a41 !important; } }

@media (max-width: 991px) {
  /* 991px */
  .page-container {
    background: #eef1f5; }
  .page-header.navbar {
    box-shadow: 0px 1px 10px 0px rgba(50, 50, 50, 0.2);
    padding: 0;
    /* Top menu */ }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle:hover {
      background-color: #f6f7fa; }
    .page-header.navbar .page-top {
      box-shadow: none; } }

@media (max-width: 767px) {
  /* 767px */
  .page-header.navbar {
    background: #FB5557;
    /* Top menu */ }
    .page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle:hover {
      background-color: #eff1f6; } }

/****
 CSS3 Spinner Bar  
****/
.page-spinner-bar > div,
.block-spinner-bar > div {
  background: #fc6e70; }

/****
    Boby
    ****/
body {
  background-color: #3D4A53; }

body.page-boxed {
  background-color: #cdd2cf; }
