.twitter-typeahead {
  width: 100%;
  display: table !important;
}

.twitter-typeahead .form-control {
  margin: 0 !important;
}

.tt-hint,
.tt-input {
  color: #999;
  font-size: 14px;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

.tt-hint[disabled] {
  background-color: #fff !important;
}


.tt-menu {
  min-width: 160px;
  margin-top: 2px;
  padding: 5px 0;
  background-color: #fff;
  border: 1px solid #ebebeb;
  -webkit-background-clip: padding-box;
     -moz-background-clip: padding;
          background-clip: padding-box;
  width: 100%;
  overflow-y: auto;
  max-height: 250px;
}

.tt-menu h3 {
  margin: 5px 0;
  padding: 6px 12px;
  font-size: 18px;
  font-weight: 700; 
  border-bottom: 1px solid #eee;
}

.modal-open .tt-menu {
  z-index: 10055 !important;
}

.tt-suggestion {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 16px;
}

.tt-suggestion:hover {
    cursor: pointer;
    background: #eee;
}

.tt-suggestion p {
  margin: 0;
}
